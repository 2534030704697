$(document).ready(function () {
    if ($('html').hasClass('loading')) {
        $('html').removeClass('loading');
    }
    if ($('table').hasClass('form-inline')) {
        $('table').removeClass('form-inline');
    }
    if ($('tr').hasClass('form-inline')) {
        $('tr').removeClass('form-inline');
    }
    if ($('td').hasClass('form-inline')) {
        $('td').removeClass('form-inline');
    }
    if (!$('input[name="save"]').hasClass('btn-success')) {
        $('input[name="save"]').addClass('btn-success');
    }

    if (!$('#lnkHomeReturn').hasClass('btn-default')) {
        $('#lnkHomeReturn').addClass('btn-default');
    }

    if (!$('.widget .btn').hasClass('btn-primary') && !$('.widget .btn').hasClass('btn-default') && !$('.widget .btn').hasClass('btn-success')) {
        $('.widget .btn').addClass('btn-primary');
    }
});

