$(function () {
    var table = $('#dns-service table.dns-records, #dns-service table.dns-zones');
    $(table).addClass('table stackable');
    var td = $(table).find('tbody tr td:not(:last-child)');
    var th = $(table).find('tbody tr th:not(:last-child)');
    $(td).each(function () {
        var ind = $(this).index();
        $(this).attr('data-label', $(th).eq(ind).html());
    });
});
